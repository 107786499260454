import React from "react";
import Contact from "./contact";
import { useTranslation } from "react-i18next";
import {
  TWITTER_LINK,
  TELEGRAM_CHANEL_LINK,
  TELEGRAM_GROUP_LINK,
  GITBOOK_LINK,
} from "../../../constants/link";
import AppRoutesEnum from "../../../constants/route";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Contact />
      <footer
        className="site-footer footer__ico pos-rel"
        data-background="assets/img/bg/footer_bg.png"
      >
        <div className="container">
          <div className="row mt-none-30 pb-70">
            <div className="col-lg-4 col-md-6 col-sm-6 mt-30">
              <div className="footer__widget wow fadeInLeft">
                <div className="footer__widget-community text-center">
                  <h3>{t("footer.join_our_community")}</h3>
                  <a href={TWITTER_LINK} target="_blank">
                    {t("common.join")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mt-30 wow fadeInRight">
              <div className="footer__widget">
                <h3>{t("footer.explore")}</h3>
                <ul className="footer__widget-links list-unstyled">
                  <li>
                    <a href={AppRoutesEnum.HOME}>{t("home")}</a>
                  </li>
                  <li>
                    <a href={AppRoutesEnum.BOT_TRADING}>{t("bot_trading")}</a>
                  </li>
                  <li>
                    <a href={AppRoutesEnum.ARBITRAGE}>{t("arbitrage")}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6 mt-30 wow fadeInUp">
              <div className="footer__widget">
                <h3>{t("footer.contact")}</h3>
                <p>marketing@gun-trader.net</p>
              </div>
            </div>
          </div>
          <div className="footer__copyright-blc ul_li_between">
            <div className="footer__copyright-text mt-15">
              Copyright © 2023 Gun Trader. All rights reserved.
            </div>
            <ul className="footer__social ul_li mt-15">
              <li>
                <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
                  <i
                    className="fa-brands fa-twitter"
                    style={{ fontSize: 20 }}
                  ></i>
                </a>
              </li>
              <li>
                <a href={TELEGRAM_CHANEL_LINK} target="_blank" rel="noreferrer">
                  <i className="fa fa-globe" style={{ fontSize: 20 }}></i>
                </a>
              </li>
              {/* <li>
                <a href={TELEGRAM_GROUP_LINK} target="_blank" rel="noreferrer">
                  <i
                    className="fa-brands fa-telegram"
                    style={{ fontSize: 20 }}
                  ></i>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="ico-contact__shape">
          <div className="shape shape--2">
            <img src="assets/img/shape/f_shape2.png" alt="" />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
